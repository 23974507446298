import React, { useState } from 'react';
import Navbar from './Navbar';


export default function Quotes() {
    const [data, setData] = useState("")
    const [dataLoaded, setDataLoaded] = useState(false);

    
    const fetchData = async () => {
        const options = {
            method: 'GET',
            headers: {
                'X-RapidAPI-Key': '87ef4bdfadmsha19117f7e173882p147106jsn5879fee115bd',
                'X-RapidAPI-Host': 'quotes15.p.rapidapi.com'
	        }}
            
        const response = await fetch('https://quotes15.p.rapidapi.com/quotes/random/', options)
        const responseData = await response.json()
        setData(responseData)
        setDataLoaded(true)
    }

    console.log(data)

  return (
    <div className="quote__container">
        <Navbar />
        <h2 className="quote__title">Quote of the Day</h2>
        
        <div className="btn__container">
        <button className="quote__btn" onClick={fetchData}><span>Give it to me!</span></button>
        </div>

    {dataLoaded ? 
        <div className="quote__content">
        <p className="quote__text">{data.content}</p>
        <p className="quote__author">"{data.originator.name}"</p>
        </div>
        :
        ""
    }

    </div>
    
  )
}
