import React from 'react';
import { Link } from "react-router-dom";

export default function Navbar() {
  return (
    <div className="navbar__container">
        <Link className="nav__link" to="/"></Link>
    </div>
  )
}
